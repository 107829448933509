<template>

    <!-- Main jumbotron for a primary marketing message or call to action -->
    <div class="jumbotron">
        <div class="container">
            <h1 class="display-3">Hello, world!</h1>
            <p>This is a template for a simple marketing or informational website. It includes a large callout called a jumbotron and three supporting pieces of content. Use it as a starting point to create something more unique.</p>
            <p>
                <a class="btn btn-primary btn-lg" href="#" role="button">Learn more &raquo;</a>
            </p>
        </div>
    </div>


    <div class="container">
        <!-- Example row of columns -->
        <div class="row">
            <div class="col-md-4">
                <h2>Heading</h2>
                <p>Will you do the same for me? It's time to face the music I'm no longer your muse. Heard it's beautiful, be the judge and my girls gonna take a vote. I can feel a phoenix inside of me. Heaven is jealous of our love, angels are crying from up above. Yeah, you take me to utopia.</p>
                <p>
                    <a class="btn btn-secondary" href="#" role="button">View details &raquo;</a>
                </p>
            </div>
            <div class="col-md-4">
                <h2>Heading</h2>
                <p>Standing on the frontline when the bombs start to fall. Heaven is jealous of our love, angels are crying from up above. Can't replace you with a million rings. Boy, when you're with me I'll give you a taste. There’s no going back. Before you met me I was alright but things were kinda heavy. Heavy is the head that wears the crown.</p>
                <p>
                    <a class="btn btn-secondary" href="#" role="button">View details &raquo;</a>
                </p>
            </div>
            <div class="col-md-4">
                <h2>Heading</h2>
                <p>Playing ping pong all night long, everything's all neon and hazy. Yeah, she's so in demand. She's sweet as pie but if you break her heart. But down to earth. It's time to face the music I'm no longer your muse. I guess that I forgot I had a choice.</p>
                <p>
                    <a class="btn btn-secondary" href="#" role="button">View details &raquo;</a>
                </p>
            </div>
        </div>

        <hr>


    </div> <!-- /container -->


</template>

<script>
//import {reactive } from 'vue';
import {useAuth  } from '@websanova/vue-auth/src/v3.js';

export default {
    setup() {
        const auth     = useAuth();

        function fetchUser() {
            auth.fetch()
        }

        return {
            fetchUser
        }

    }
}


</script>


<style>

.starter-template {
  padding: 3rem 1.5rem;
  text-align: center;
}
</style>
