<template>
    <Jumbotron />

    <!--
    <div class="col-12">
        <Jumbotron />
    </div>
-->
</template>

<script>
// @ is an alias to /src
import Jumbotron from '@/components/bootstrapDashboard/Jumbotron.vue'

export default {
    name: 'JumbotronView',
    components: {
        Jumbotron
    }
}
</script>
